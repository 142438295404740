



















import { Component, Vue } from 'vue-property-decorator';
import Nav from './components/Nav.vue';
import Banner from './components/Banner.vue';
import Info from './components/Info.vue';
import Footer from './components/Footer.vue';

import {mapState} from 'vuex';

@Component({
  components: {
    Nav,
    Banner,
    Info,
    Footer,
  },
})

export default class App extends Vue {
  name!: "App";
  data!: () => ({

    });

}

