

































export default {
    name: "Nav",
    data: () => ({
    user: ''
    }),
}
