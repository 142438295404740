<template>
    <div class="col-12">
        <div class="text">

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.text {
    text-align: center;
    font-size: 1.1em;
    font-weight: normal;
}

.col-12 {
    background-color: #cdf0fa;
}

</style>