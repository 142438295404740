<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xs-12 col-lg-5 col-md-12 col-sm-12">
                <img src="../assets/SP_Logo2.svg" class="logo">
            </div>
            <div class="col-s-12 col-lg-2 col-md-3 col-sm-12 info">
                <a href="tel:+49355355480" target="_blank" class="icon"><img src="../assets/Icons/phone.svg" class="icon"></a>
                <div class="info-inline"><a href="tel:+49355355480" target="_blank">+49 (0)355-35548-0</a></div>
            </div>
            <div class="col-s-12 col-lg-2 col-md-3 col-sm-12 info">
                <a href="tel:+49355355419" target="_blank" class="icon"><img src="../assets/Icons/fax.svg"  class="icon"></a>
                    <div class="info-inline"><a href="tel:+49355355419" target="_blank">+49 (0)355-35548-19</a></div>
            </div>
            <div class="col-s-12 col-lg-2 col-md-3 col-sm-12 info">
                <a href="mailto:info@steuergestaltung.biz" target="_blank" class="icon"><img src="../assets/Icons/email.svg"  class="icon"></a>
                <div class="info-inline"><a href="mailto:info@steuergestaltung.biz" target="_blank">info@steuergestaltung.biz</a></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.icon img {
    animation: none;
}

.icon img:hover {
    animation: shake 1s infinite;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-2px, -2px, 0);
  }

  20%, 80% {
    transform: translate3d(2px, -2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 2px, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 2px, 0);
  }
}

.container-fluid {
    background-color: white;
}

a {
    color: black;
    text-decoration: none;
}

.logo{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-height: 8vh;
    max-width: auto;
}

.info {
    text-align: center;
    margin-top: 3vh;
    text-decoration: none;
}

@media screen and (max-width: 1263px) {
  .info {
    text-align: center;
    margin-top: 0vh;
  }
  .logo{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-height: 8vh;
    max-width: auto;
}
}

.info-inline {
    text-align: center;
    margin-top: .5vh;
}

.icon {
    max-height: 3vh;
    max-width: 4vw;
}

</style>