import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/homede.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../components/Impressum.vue')
  },
  {
    path: '/en/home',
    name: 'Homeen',
    component: () => import('../views/homeen.vue')
  },
  {
    path: '/pl/home',
    name: 'Homepl',
    component: () => import('../views/homepl.vue')
  },
  {
    path: '/Datenschutz',
    name: 'Datenschutz',
    component: () => import('../views/Datenschutz.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
