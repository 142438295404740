<template>
            <div class="container col-6">
                <div class="row">
                    <div class="col-6">
                        <div class="menu-item">
                            <router-link to="/impressum">
                                Impressum
                            </router-link>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="menu-item">
                            <router-link to="/Datenschutz">
                                Datenschutzhinweis
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>
</template>

<script>
export default {
    name: "Footer",
}
</script>

<style scoped>
.menu-item {
    position: relative;
    text-align: center;
    color: black;
    padding-right: 3vw;
    padding-left: 3vw;
}

.menu-item a {
    text-decoration: none;
    color: black;
}

row {
    padding-bottom: 10px;
}

.container {
    max-height: 60px;
}


</style>